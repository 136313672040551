import React from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import useSwitchLanguage from '../../hooks/useSwitchLanguage'
import { useIntl } from 'react-intl'
import cx from 'classnames'
import { headerItems } from '../mock-data/header'
import footerItems from '../mock-data/footer'

const MainLayout = ({ children, topSlot, className }) => {
  const { locale } = useIntl()
  const handleChangeLang = useSwitchLanguage(locale)

  return (
    <div className={cx('outer-wrapper', className)}>
      <Header
        items={headerItems}
        locale={locale}
        onChangeLang={handleChangeLang}
      />
      {topSlot ? <div className="top-slot">{topSlot}</div> : null}
      <div className="content">{children}</div>
      <Footer items={footerItems} />
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
  topSlot: PropTypes.node,
  className: PropTypes.string,
}

export default MainLayout
