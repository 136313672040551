import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const Button = forwardRef(({ disabled, text, as, size, ...restProps }, ref) => {
  const classes = cx(`button button-${size}`)
  const Component = as
  const t = useTranslations()
  return (
    <Component
      ref={ref}
      className={classes}
      disabled={disabled}
      {...restProps}
      aria-label={t(text)}
    >
      <span className="button-text">{t(text)}</span>
    </Component>
  )
})

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  as: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf(['a', 'button'])]),
  disabled: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
}

Button.defaultProps = {
  size: 'large',
}

export default Button
