import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'
import cx from 'classnames'

const Message = ({
  button,
  title,
  text,
  isFullScreen,
  htmlText,
  isLongText,
}) => {
  // force refresh on hydration
  const [clientKey, setClientKey] = useState(null)
  useEffect(() => setClientKey(Math.random()), [])
  // translations
  const t = useTranslations()
  return (
    <div
      className={cx('message', {
        'is-full-screen': isFullScreen,
        'is-long-text': isLongText,
      })}
    >
      <h1 className="message-title">{t(title)}</h1>
      {htmlText ? (
        <p
          key={clientKey} /* force update on every re-render */
          className="message-text"
          dangerouslySetInnerHTML={{ __html: t(htmlText) }}
        />
      ) : (
        <p className="message-text"> {t(text)}</p>
      )}
      <Button
        as={button.as}
        text={button.text}
        size="xlarge"
        onClick={button.onClick}
        href={button.href}
        to={button.to}
      />
    </div>
  )
}
Message.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  isFullScreen: PropTypes.bool,
  htmlText: PropTypes.string,
}

export default Message
