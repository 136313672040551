import React from 'react'
import Message from '../components/messages/Message'
import Link from '../components/links/LinkLocalized'

const button = {
  as: Link,
  text: 'message:explore-aucta-up',
  to: '/',
}

const SubmissionSuccessView = () => {
  return (
    <>
      <Message
        isFullScreen
        title="message:thank-you"
        htmlText="message:verification-link"
        button={button}
      />
    </>
  )
}

export default SubmissionSuccessView
